<template >
    <EmailForm />

</template>

<script>
import { defineComponent } from 'vue';
import EmailForm from '@/components/EmailForm.vue'; 

export default defineComponent({
name: 'RecoverPasswordView',

components: {
    EmailForm,
},
});
</script>

<style>

</style>
