// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import { VApp } from 'vuetify/components'

export default createVuetify({
  components: {
    VApp
  },
  theme: {
    defaultTheme: 'light', // Asegurarte de que el tema 'light' se está usando
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#0455B7',
          secondary: 'white',
          accent: '#F6CB0B',
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FFC107',
        }
        
      }
    },
    variables: true
  },
  defaults: {
    global: {
      style: {
        fontFamily: 'Plus Jakarta Sans, sans-serif',
      }
    }
  }
});
