<template>
    <div id="container">
        <v-card id="login">
            <img :src="imageSrc" alt="GROW">
            <h1 class="mb-2 mt-2 text-center">Inicio de Sesión</h1>
            <v-form ref="loginForm" @submit.prevent>

              <v-text-field
                label="Email"
                v-model="username"
                variant="solo"
                type="email"
                :rules="[rules.email]"
                class="mb-2"
                >
              </v-text-field>
              <v-text-field
                  label="Contraseña"
                  v-model="password"
                  variant="solo"
                  :type="showPassword ? 'text' : 'password'"
                  :append-inner-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append-inner="togglePasswordVisibility"
                  class="mb-2"
                  :rules="[rules.password]"
                  >
              </v-text-field>
              <v-btn block color="accent" @click="login" :loading="loading">Iniciar sesión</v-btn>

            </v-form>
        <router-link to="/recover-password" class="text-center text-accent mt-2" style="text-decoration: none;">¿Olvidaste tu contraseña?</router-link>
            
        </v-card>
    </div>
  </template>
  <script>

  import axios from 'axios';
  import { mapActions } from 'vuex';


export default {
  data() {
    return {
      username: '',
      password: '',
      newUsername: '',
      newPassword: '',
      dashboardMessage: '',
      imageSrc: require('../assets/GROW.png'),
      showPassword: false,
      rules: {
        email: value => {
          const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          return emailPattern.test(value) ? true : 'Debes ingresar un correo electrónico válido';
        },
        password: value => value ? true : 'Debes ingresar una contraseña',
      },
      loading: false
    };
  },
  methods: {
    ...mapActions(['triggerSnackbar']),
    async login() {
      this.loading = true;
      try {
        const valid = await this.$refs.loginForm.validate();
        if(!valid.valid){
          this.loading = false;
          return
        }
        const verifyUSer = await axios.get(`${process.env.VUE_APP_URL}/login/searchUser?email=${this.username}`);
          const messageUser = verifyUSer?.data?.message;
          if(messageUser == 'pasword is empty'){
            this.$router.push({path: '/create-password', query: {email: this.username}})
            return;
          }
        const res = await axios.post(`${process.env.VUE_APP_URL}/login/comparePassword`, {
          email: this.username,
          password: this.password
        });
        const statusCode = res?.status;
        const message = res?.data?.message;
        if(statusCode != 200 && statusCode != '200'){
          this.showSnackbar(message, "error", 3000);
          this.loading = false;
          return;
        }
        
        if(!message){
          this.showSnackbar("Hubo un error intenta de nuevo", "error", 3000);
          this.loading = false;
          return;
        }

        if(message == 'wrong credentials'){
          this.showSnackbar("Las credenciales son incorrectas", "error", 3000);
          this.loading = false;
          return;
        }

        const token = res?.data?.token;
        localStorage.setItem('token', token);
        this.loading = false;
        this.$router.push({ name: 'Surveys' });
      } catch (error) {
        this.showSnackbar("Hubo un error intenta de nuevo", "error", 3000);
        this.loading = false;
        console.error(error);
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword; // Alternar entre mostrar y ocultar la contraseña
    },
    showSnackbar(message, color, timeout) {
      this.triggerSnackbar({
        message: message,
        color: color,
        timeout: timeout
      });
    }
  }
};
</script>

<style scope>

#login {
    margin: 3rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    min-width: 350px;
    max-width: 500px;
    padding: 40px;
    background-color: rgba(255, 255, 255, 0.5) !important;

}

.custom-link {
  color: var(--v-accent-base); /* Usar el color accent de Vuetify */
  text-decoration: none; /* Eliminar subrayado si no lo deseas */
}

.custom-link:visited {
  color: var(--v-accent-base); /* Mantener el color accent cuando el enlace ha sido visitado */
}

.custom-link:hover {
  color: var(--v-accent-darken1); /* Cambiar el color al hacer hover */
  text-decoration: underline;
}
</style>