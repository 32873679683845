<template>
    <div class="app-container">
      <Sidebar routh="notifications" />
      <div class="main-content">

      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  
  // Components
  import HelloWorld from '../components/HelloWorld.vue';
  import Sidebar from '../components/sidebar.vue';
  import SearchBar from '../components/SearchBar.vue';
  import SurveyTable from '../components/SurveyTable.vue';
  
  export default defineComponent({
    name: 'HomeView',
  
    components: {
      HelloWorld,
      Sidebar,
      SearchBar,
      SurveyTable
    },
  });
  </script>
  
  <style scoped>
  .app-container {
    display: flex;
  }
  
  .main-content {
    flex: 1;
    padding: 20px;
    background-color: #f8f9fa;
  }
  </style>