<template>
    <div id="container">
      
        <v-card id="login">
            <img :src="imageSrc" alt="GROW">
            <h1 class="mb-2 mt-2">{{ title }}</h1>
            <v-form ref="loginForm" @submit.prevent>
              <v-text-field
                label="Contraseña"
                v-model="password"
                variant="solo"
                :type="showPassword1 ? 'text' : 'password'"
                :append-inner-icon="showPassword1 ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append-inner="togglePasswordVisibility1"
                :rules="[rules.password]"
                class="mb-2"
                >
              </v-text-field>
              <v-text-field
                  label="Confirmar contraseña"
                  v-model="passwordc"
                  variant="solo"
                  :type="showPassword2 ? 'text' : 'password'"
                  :append-inner-icon="showPassword2 ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append-inner="togglePasswordVisibility2"
                    :rules="[rules.password, rules.passwordcompare]"
                    class="mb-2"
                  >
              </v-text-field>
              <v-btn block color="accent"  @click="changePassword" :loading="loading">Guardar</v-btn>
            </v-form>
            
        </v-card>
      
      
    </div>
  </template>
  <script>

  import { mapGetters } from 'vuex';
  import { mapActions } from 'vuex';

  import axios from 'axios';


export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
        passwordc: '',
      password: '',
      newUsername: '',
      newPassword: '',
      dashboardMessage: '',
      imageSrc: require('../assets/GROW.png'),
      email: this.$route.query.email,
      showPassword1: false,
      showPassword2: false,
      rules: {
        email: value => {
          const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          return emailPattern.test(value) ? true : 'Debes ingresar un correo electrónico válido';
        },
        password: value => value ? true : 'Debes ingresar una contraseña',
        passwordcompare: v => v == this.$data.password ? true : 'Las contraseñas deben ser iguales'
      },
      loading: false,
    };
  },
  methods: {
    ...mapActions(['triggerSnackbar']),
    async changePassword(){
      this.loading = true;
      const valid = await this.$refs.loginForm.validate();
        if(!valid.valid){
          this.loading = false;
          return
        }
      const routh = this.$route.name;

      let email = this.email;
      
      if(routh == 'Create password'){
        email = this.email;
      }else{
        email = this.getEmail;
      }

      try{
        //console.log(routh)
        const res = await axios.post(`${process.env.VUE_APP_URL}/login/savePassword`, {
          email: email,
          password: this.password
        });
        const status = res?.status;
        if(status != 200 && status != '200'){
          this.showSnackbar("Hubo un error intenta de nuevo", "error", 3000);
          this.loading = false;
          return;
        }

        const message = res?.data?.message || '';

        if(message == ''){
          this.showSnackbar("Hubo un error intenta de nuevo", "error", 3000);
          this.loading = false;
          return;
        }

        if(message == 'password not stored'){
          this.showSnackbar("Hubo un error intenta de nuevo", "error", 3000);
          this.loading = false;
          return;
        }

        this.loading = false;
        this.showSnackbar("La contraseña se guardo correctamente", "success", 3000);
        this.$router.push({ name: 'Login' });
        /*setTimeout(() => {
          this.$router.push({ name: 'Login' });
        }, 3000);*/ // 3000 milisegundos = 3 segundos
      }catch(err){
        this.showSnackbar("Hubo un error intenta de nuevo", "error", 3000);
          this.loading = false;
          return;
      }
    },
    togglePasswordVisibility1() {
      this.showPassword1 = !this.showPassword1; // Alternar entre mostrar y ocultar la contraseña
    },
    togglePasswordVisibility2() {
      this.showPassword2 = !this.showPassword2; // Alternar entre mostrar y ocultar la contraseña
    },
    showSnackbar(message, color, timeout) {
      this.triggerSnackbar({
        message: message,
        color: color,
        timeout: timeout
      });
    }

  },
  computed: {
      ...mapGetters(['getEmail']),
    }
};
</script>

<style scope>

#login {
    margin: 3rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    min-width: 350px;
    max-width: 500px;
    padding: 40px;
    background-color: rgba(255, 255, 255, 0.5) !important;

}

img{
    color: #4b7cb9;
}



</style>