<template>
  <div :class="{'app-container': true, 'modal-active': currentComponent != 'SurveyTable'}">
    <v-app-bar v-if="smAndDown" class="bg-primary">
      <template v-slot:prepend>
        <v-app-bar-nav-icon @click="toggleDrawer"></v-app-bar-nav-icon>
      </template>
      <v-app-bar-title>Mis encuestas</v-app-bar-title>
    </v-app-bar>
    <!-- <Sidebar route="surveys" @change-component="updateComponent"/> -->
    <v-navigation-drawer
      v-model="drawer"
      :permanent="!smAndDown"
      class="bg-primary">
      <Sidebar route="surveys" @change-component="updateComponent"/>
    </v-navigation-drawer>
    <div :class="{'main-content':true, 'mobile-content': smAndDown}" >
      <!-- Mostrar el indicador de carga mientras loading sea true -->
      <div v-if="loading" class="container">
        <div class="flipping"></div>
      </div>

      <!-- Mostrar los componentes SearchBar y SurveyTable cuando loading sea false -->
      <div v-else>
        <SearchBar @search="filterSurveys"/>
        <SurveyTable :ismobile="smAndDown" :completedSurveys="filteredCompletedSurveys" :pendingSurveys="filteredPendingSurveys"/>
        <!-- <button @click="sendEmail">Enviar Email</button> -->
      </div>
    </div>
    <EmailSent :status="currentComponent" @change-component="updateComponent" v-if="currentComponent != 'SurveyTable'" class="email-sent-popup"/>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';

// Components
import HelloWorld from '../components/HelloWorld.vue';
import Sidebar from '../components/sidebar.vue';
import SearchBar from '../components/SearchBar.vue';
import SurveyTable from '../components/SurveyTable.vue';
import EmailSent from '../components/EmailSent.vue';

import axios from 'axios';
import { mapGetters, mapActions, mapState } from 'vuex';
import { useDisplay } from 'vuetify';

export default defineComponent({
  name: 'HomeView',
  components: {
    HelloWorld,
    Sidebar,
    SearchBar,
    SurveyTable,
    EmailSent
  },
  data(){
    return {
      loading: true,
      searchQuery: '',
      pendingSurveys: [],
      completedSurveys: [],
      currentComponent: 'SurveyTable',
      drawerM: false,
      rail: true,
      perma: false,
    }
  },
  async mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    try {
      const email = this.getEmail;  // Obtiene el correo electrónico desde Vuex
      this.updateDrawer(false)
      // const email = "Christiana.Romaguera50@yahoo.com";
      // const email = "Shany_Bernhard12@hotmail.com";
      const criteriaUser = `select First_Name from Contacts where Email='${email}'`; // Formatea el criterio

      // Realiza la petición con el criterio como query param
      const userData = await axios.get(`${process.env.VUE_APP_URL}/zoho/fetchData`, {
        params: {
          criteria: criteriaUser
        }
      });


      if(userData?.data?.message != 'data fetched successfully') {
        this.showSnackbar("User not found", "error", 3000);
        this.loading = false;
        return;
      }
      if(!userData?.data?.recordMap?.data[0]?.id) {
        this.showSnackbar("User not found", "error", 3000);
        this.loading = false;
        return;
      }
      const userId = userData?.data?.recordMap?.data[0]?.id;

      const criteriaSurveys = `select Encuesta.Name as name, Status as stage, Fecha_de_Vencimiento as dueDate, Encuesta.Survey_Url as url, Colaborador.Department as department, Sesion, Coach, Colaborador.Email as email,  Hora_de_Completacion from Encuestas_Asignadas where Colaborador='${userId}'`;

      const getSurveys = await axios.get(`${process.env.VUE_APP_URL}/zoho/fetchData`, {
        params: {
          criteria: criteriaSurveys
        }
      });

      if(getSurveys?.data?.message != 'data fetched successfully') {
        this.showSnackbar('Surveys not found', "error", 3000);
        this.loading = false;
        return;
      }

      if(!getSurveys?.data?.recordMap?.data) {
        this.showSnackbar('Surveys not found', "error", 3000);
        this.loading = false;
        return;
      }

      const surveys = getSurveys?.data?.recordMap?.data;

      if(surveys.lenght == 0){
        this.loading = false;
      }

      const pendingSurveysRaw = surveys.filter(survey => survey.stage != 'Contestada');
      const completedSurveysRaw = surveys.filter(survey => survey.stage == 'Contestada');

      this.pendingSurveys = pendingSurveysRaw.map(survey => ({
        id: survey.id,
        name: survey.name,
        sesion: survey.Sesion,
        dueDate: survey.dueDate,
        stage: {
          name: 'Not-started',
          value: survey.stage
        },
        department: survey.department,
        url: `${survey.url}?survey=${survey.Sesion}&coach=${survey.Coach}&email=${survey.email}&usdId=${survey.id}`
      }))

      this.completedSurveys = completedSurveysRaw.map(survey => ({
        id: survey.id,
        name: survey.name,
        sesion: survey.Sesion,
        dueDate: survey.Hora_de_Completacion.substring(0,10),
        stage: {
          name: 'Completada',
          value: survey.stage
        },
        department: survey.department,
        url: survey.url
      }))

      this.loading = false;
    } catch (error) {
      console.error('Error al obtener los datos:', error);
      this.showSnackbar('Error al obtener los datos', "error", 3000);
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters(['getEmail', 'drawer', 'rail', 'perma', 'smAndDown']),
    ...mapState(['drawerM']),
    filteredPendingSurveys() {
      return this.pendingSurveys.filter(survey => 
        survey.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    filteredCompletedSurveys() {
      return this.completedSurveys.filter(survey => 
        survey.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  methods: {
    ...mapActions(['triggerSnackbar', 'toggleDrawer', 'updateRail', 'updatePerma', 'updateSmAndDown', 'updateDrawer']),
    filterSurveys(searchText) {
      this.searchQuery = searchText;
    },
    updateComponent(componentName) {
      this.currentComponent = componentName; // Cambia el componente actual
    },
    showSnackbar(message, color, timeout) {
      this.triggerSnackbar({
        message: message,
        color: color,
        timeout: timeout
      });
    },
    handleResize() {
      const smAndDown = window.innerWidth < 800; // Ajusta el tamaño según tus necesidades
      this.updateSmAndDown(smAndDown); // Despacha la acción de Vuex
    },
    // toggleDrawer() {
    //   this.toggleDrawer(); // Usando la acción Vuex
    // }
  },
  onBeforeUnmount() {
    // Limpia el listener al destruir el componente
    window.removeEventListener('resize', this.handleResize);
  },
})
</script>

<style scoped>
.app-container {
  display: flex;
  height: 100vh; /* Asegura que ocupe toda la ventana */
}

.modal-active::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Fondo oscuro */
  z-index: 998;
}

.main-content {
  flex: 1;
  padding: 20px;
  background-color: #f8f9fa;
  overflow-y: auto; /* Permitir el scroll vertical */
  box-sizing: border-box; /* Incluir padding en el cálculo de la altura */
}

.mobile-content {
  height: calc(100vh - 64px); /* Ocupa toda la altura disponible */
}
.container {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  height: 100vh; /* Ocupar toda la altura de la ventana */
}

.flipping {
   width: 70.4px;
   height: 70.4px;
   background: #0455B7;
   transform: perspective(264px) rotateX(0);
   animation: flipping-qayox5hg 1.0499999999999998s infinite;
}

@keyframes flipping-qayox5hg {
   100% {
      transform: perspective(264px) rotateX(180deg);
   }
}

.email-sent-popup {
  position: fixed; /* Se queda fijo en la pantalla, fuera del flujo del documento */
  top: 50%; /* Centrado verticalmente */
  left: 50%; /* Centrado horizontalmente */
  transform: translate(-50%, -50%); /* Ajuste para centrar completamente */
  z-index: 999; /* Asegura que esté encima de todos los demás elementos */
  background-color: white; /* Fondo blanco para el popup */
  padding: 20px; /* Espaciado interior */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombras para destacar el popup */
  border-radius: 10px; /* Bordes redondeados */
  width: 300px; /* Ancho fijo para el popup */
  text-align: center;
  height: 300px;
}

/* Opcional: Fondo desenfocado o transparente detrás del popup */

</style>