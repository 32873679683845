<template>
    <div>
      <v-expansion-panels v-model="tables" multiple>
        <v-expansion-panel  value="pendientes">
          <v-expansion-panel-title>
            <p :class="{'text-h5': !ismobile, 'text-h6': ismobile}">Pendientes</p>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-data-table
              :headers="headersPending"
              :items="pendingSurveys"
              itemsPerPageText="Elementos por página"
              :itemsPerPageOptions="[5, 10, 25]"
              itemsPerPage="5"
              :style="{fontSize: (ismobile ? 0.7 : 0.875) + 'rem'}"
            >
                <template v-slot:no-data>
                    No hay encuestas pendientes
                </template>

              <template v-slot:item.stage="{ item }">
                <span :class="'badge ' + item.stage.name">{{ item.stage.value }}</span>
              </template>

              <template v-slot:item.url="{ item }">
                <v-btn icon @click="openUrl(item.url)" variant="text">
                  <v-icon icon="mdi-clipboard-list" size="x-large"></v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel value="completadas">
          <v-expansion-panel-title>
            <p :class="{'text-h5': !ismobile, 'text-h6': ismobile}">Completadas</p>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-data-table
              :headers="headersCompleted"
              :items="completedSurveys"
              itemsPerPageText="Elementos por página"
              :itemsPerPageOptions="[5, 10, 25]"
              itemsPerPage="5"
              noDataAvailableText="et"
              :style="{fontSize: (ismobile ? 0.7 : 0.875) + 'rem'}"
            >
                <template v-slot:no-data>
                  No hay encuestas completadas
                </template>

              <template v-slot:item.stage="{ item }">
                <span :class="'badge ' + item.stage.name">{{ item.stage.value }}</span>
              </template>

              <template v-slot:item.url="{ item }">
                <v-btn icon @click="openUrl(item.url)" variant="text">
                  <v-icon icon="mdi-clipboard-list" size="x-large"></v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
  
      
    </div>
  </template>
  
  <script>
  export default {
    props: {
      pendingSurveys: {
        type: Array,
        required: true
      },
      completedSurveys: {
        type: Array,
        required: true
      },
      ismobile: {
        type: Boolean
      }
    },
    data() {
      return {

        headersPending: [
          { title: 'Nombre', value: 'name', width: '30%' },
          { title: 'Sesión', value: 'sesion', width: '15'},
          { title: 'Fecha de vencimiento', value: 'dueDate', width: '15%' },
          { title: 'Etapa', value: 'stage', width: '15%' },
          { title: 'Departamento', value: 'department', width: '15%' },
          { title: 'Encuesta', value: 'url', width: '10%' },
        ],
        headersCompleted: [
          { title: 'Nombre', value: 'name', width: '35%' },
          { title: 'Sesión', value: 'sesion', width: '15'},
          { title: 'Fecha de respuesta', value: 'dueDate', width: '20%' },
          { title: 'Departamento', value: 'department', width: '15%' },
        ],
        tables: ['pendientes', 'completadas']
      };
    },
    methods: {
      openUrl(url) {
        window.open(url, '_blank'); // Abre la URL en una nueva pestaña
      }
    }
  }
  </script>
  
  <style scoped>
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .table th, .table td {
    padding: 10px;
    border: 1px solid #dee2e6;
  }
  
  .badge {
    padding: 5px 10px;
    border-radius: 4px;
    color: white;
  }
  
  .survey-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .badge.Not-started { background-color: gray; }
  .badge.In-progress { background-color: blue; }
  .badge.Completada { background-color: green; }
  .badge.High { background-color: red; }
  .badge.Medium { background-color: orange; }
  .badge.Low { background-color: green; }
  </style>
  