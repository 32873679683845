<template>
  <v-text-field
    label="Buscar encuestas"
    v-model="searchText"
    @input="emitSearchText"
    variant="solo"
  />
</template>

<script>
export default {
  data() {
    return {
      searchText: '',
    };
  },
  methods: {
    emitSearchText() {
      this.$emit('search', this.searchText);
    },
  },
};
</script>
  