import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
        email: '',
        snackbar: {
          active: false,
          message: '',
          color: 'info',
          timeout: 3000,
        },
        perma: false,
        drawerM: false,
        rail: true,
        smAndDown: false,

    }
  },
  mutations: {
    setEmail(state, value){
        state.email = value;
    },
    SHOW_SNACKBAR(state, { message, color, timeout }) {
        state.snackbar.active = true;
        state.snackbar.message = message;
        state.snackbar.color = color || 'info';
        state.snackbar.timeout = timeout || 3000;
    },
    HIDE_SNACKBAR(state) {
        state.snackbar.active = false;
        state.snackbar.message = '';
    },
    SET_DRAWER(state, value) {
      state.drawerM = value;
    },
    SET_RAIL(state, value) {
      state.rail = value;
    },
    SET_PERMA(state, value) {
      state.perma = value;
    },
    SET_SM_AND_DOWN(state, value) {
      state.smAndDown = value;
    }
  },
  actions: {
    updateEmail({ commit }, value){
        commit('setEmail', value);
    },
    triggerSnackbar({ commit }, payload) {
        commit('SHOW_SNACKBAR', payload);
    },
    closeSnackbar({ commit }) {
        commit('HIDE_SNACKBAR');
    },
    toggleDrawer({ commit, state, getters }) {
      commit('SET_DRAWER', !state.drawerM);
    },
    updateRail({ commit }, value) {
      commit('SET_RAIL', value);
    },
    updatePerma({ commit }, value) {
      commit('SET_PERMA', value);
    },
    updateSmAndDown({ commit }, value) {
      commit('SET_SM_AND_DOWN', value);
    },
    updateDrawer({ commit }, value) {
      commit('SET_DRAWER', value)
    }
  },
  getters: {
    getEmail: state => state.email,
    snackbar: state => state.snackbar,
    drawerM: state => state.drawerM,
    rail: state => state.rail,
    perma: state => state.perma,
    smAndDown: state => state.smAndDown,
    drawer: state => state.drawerM || !state.smAndDown,
  },
});

export default store;