import { createRouter, createWebHashHistory, createWebHistory  } from 'vue-router'
import store from '@/store';

import HomeView from '../views/HomeView.vue'
import Login from '../views/LoginView.vue'
import PasswordView from '@/views/ChangePasswordView.vue'
import NotificationsView from '@/views/NotificationsView.vue'
import RecoverPasswordView from '@/views/RecoverPasswordView.vue';

import axios from 'axios'
import EmailSentView from '@/views/EmailSentView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/login'
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: { requiresAuth: true }, // Solo accesible si el usuario está autenticado

  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/change_password',
    name: 'Change password',
    component: PasswordView,
  },
  {
    path: '/surveys',
    name: 'Surveys',
    component: HomeView,
    meta: { requiresAuth: true }, // Solo accesible si el usuario está autenticado
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: NotificationsView,
    meta: { requiresAuth: true }, // Solo accesible si el usuario está autenticado
  },
  {
    path: '/create-password',
    name: 'Create password',
    component: PasswordView,
  },
  {
    path: '/email-sent',
    name: 'Email Sent',
    component: EmailSentView,
    meta: { requiresAuth: true }, // Solo accesible si el usuario está autenticado
  },
  {
    path: '/recover-password',
    name: 'Recover password',
    component: RecoverPasswordView,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {

  if(to.name == 'Login'){
    
    const isAuthenticated = !!localStorage.getItem('token'); // Ejemplo con localStorage
    const email = to.query.email || '';
    if (!isAuthenticated && email == '') {
      next();
      return;
    }
    if (!isAuthenticated && email != '') {
      try{
        const res = await axios.get(`${process.env.VUE_APP_URL}/login/searchUser?email=${email}`);
        const message = res?.data?.message;
        if(message == 'pasword is empty'){
          next({name: 'Create password', query: {email: email}})
          return;
        }
          next();
          return;
      }catch(err){
        next();
        return;
      }
    }
    const token = localStorage.getItem('token'); // Ejemplo con localStorage
    try{
      const res = await axios.post(`${process.env.VUE_APP_URL}/login/verifyToken`, {
        jwt: token
      });
      next({name: 'Surveys'});
      return;
    }catch(err){
      localStorage.removeItem('token');
      if(email != ''){
        try{
          const res = await axios.get(`${process.env.VUE_APP_URL}/login/searchUser?email=${email}`);
          const message = res?.data?.message;
          if(message == 'pasword is empty'){
            next({name: 'Create password', query: {email: email}})
            return;
          }
          next();
          return;
        
        }catch(err){
          next();
          return;
        }
      }
    }
   
  }else if(to.name == 'Create password'){
    const email = to.query.email || '';
    if(email == ''){
      next({name: 'Login'})
    }
    try{
      const res = await axios.get(`${process.env.VUE_APP_URL}/login/searchUser?email=${email}`);
      const message = res?.data?.message;
      if(message == 'password is not empty' || message == 'user not found'){
        next({name: 'Login'})
      }
      next();
    
    }catch(err){
      next({name: 'Login'})
    }
  }else if (to.matched.some(record => record.meta.requiresAuth)) {

    const isAuthenticated = !!localStorage.getItem('token'); // Ejemplo con localStorage

    if (!isAuthenticated ) {
      next({ name: 'Login' });
      return;
    }

    const token = localStorage.getItem('token'); // Ejemplo con localStorage
    try{
      const res = await axios.post(`${process.env.VUE_APP_URL}/login/verifyToken`, {
        jwt: token
      });
      const status = res?.status;
      if (status != 200 && status != '200' ) {
        localStorage.removeItem('token');
        next({ name: 'Login' });
        return;
      }
      store.dispatch('updateEmail', res?.data?.loggedEmail)
        next();
        return;
    }catch(err){
      localStorage.removeItem('token');
      next({ name: 'Login' });
      return;
    }
  }
    next();
    return;
});

export default router
