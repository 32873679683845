<template>
    <div class="sidebar bg-primary">
      <div>
        <div class="brand">
          <img :src="imageSrc" alt="GROW" class="logo">
          
          <p>GROW</p>
        </div>
        <v-divider :thickness="1" class="border-opacity-75" color="white"></v-divider>
      </div>
      
      <div class="list-pages">
        <v-list dense class="list  bg-primary">
          <v-list-item
            v-for="(item, i) in pages"
            :key="i"
            :class="item.active"
            @click="$router.push(item.route)"
          >
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>

            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
        <v-menu location="end">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              variant="text"
              class="text-none btn-settings d-flex justify-start"
              prepend-icon="mdi-menu"
              block
              size="x-large"
            >
            Configuración
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in settings"
              :key="index"
              @click="item.action"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      
      <div class="bottom-menu">
        <v-divider :thickness="1" class="border-opacity-75" color="white"></v-divider>
        <v-btn
              variant="text"
              class="text-none btn-settings d-flex justify-start"
              prepend-icon="mdi-logout"
              block
              size="x-large"
              @click="logout"
            >
            Cerrar sesión
            </v-btn>
      </div>
    </div>
  </template>
  
  <script>

  import { mapGetters, mapActions } from 'vuex';

  import axios from 'axios';


  export default {
    name: 'Sidebar',
    props: {
      route: {
          type: String,
          required: true, // Esto es opcional, pero buena práctica
        }
    },
    data(){
        return {
            surveyClass: 'inactive',
            notificationClass: 'bg-primary',
            pages: [
              { text: 'Mis encuestas', icon: 'mdi-checkbox-multiple-marked-outline', active: '', route: {name: 'Surveys'} },
            ],
            imageSrc: require('../assets/culturagrow_logo.jpeg'),
            showSettingsMenu: false,
            settings: [
              {title: 'Cambiar contraseña', route:{name: 'Change password'}, action: this.sentEmail}
            ]
        }
    },
    mounted(){
        const ruta = this.route;
        if(ruta == 'surveys'){
            this.pages[0].active = 'active';
            //this.pages[1].active = 'bg-primary';
        }
        if(ruta == 'notifications'){
            this.pages[1].active = 'active';
            //this.pages[0].active = 'bg-primary';
        }
    },
    methods: {
      ...mapActions(['toggleDrawer']),
      logout(){
        localStorage.removeItem('token');
        this.$router.push({ name: 'Login' });
      },
      async sentEmail(){
        try{
          this.toggleDrawer();
          this.$emit('change-component', 'sendEmail');
          const email =  this.getEmail;
          const res = await axios.post(`${process.env.VUE_APP_URL}/login/changePassword`, {
            email: email
          });
          //this.$router.push({ name: 'Email Sent' , params: {test: 'test'}});
          this.$emit('change-component', 'emailSent');
          
        }catch(err){
          console.error('Error al obtener los datos:', err);
          this.$emit('change-component', 'emailNotSent');
          this.showSnackbar("Hubo un error intenta de nuevo", "error", 3000);
          this.loading = false;
        }
      }
    },
    computed: {
      ...mapGetters(['getEmail']),
    }
  }
  </script>
  
  <style scoped>
  .sidebar {
    width: 250px;
    color: rgb(255, 255, 255);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .brand {
    padding: 20px;
    font-size: 24px;
    align-content: center;
    display: flex;
    height: 80px;
    white-space: nowrap; /* Evita que el texto se divida en varias líneas */
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  .list{
    padding: 0;
    color: white;
    width: 100%;
  }
  
  li {
    padding: 10px 0;
    cursor: pointer;
  }
  .inactive{
    color: white;
  }
  
  .active {
    background-color: rgba(255, 255, 255, 0.877);
    color: black;
    padding: 10px;
    border-radius: 8px !important; 
  }
  
  .bottom-menu {
    font-size: 18px;
    color: white;
    height: 75px;
  }

  a {
  text-decoration: none;
  color: white;
}

 li.active a {
    color: black;
 }

 .logo{
  width: 35px;
  height: 35px;
  border-radius: 7px ;
  margin-right: 8px;
 }

 .list-pages{
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
 }

 .logout{
  padding: 20px;
 }
 .settings{
  font-size: 18px;
  color: white;
  padding: 20px;
 }

 .settings-menu {
  background-color: #0455B7; /* Ajusta el color de fondo según tu diseño */
  color: white;
  padding: 10px 0;
}
.settings-menu .v-list-item {
  padding: 10px 20px; /* Espaciado interno para cada opción */
  cursor: pointer;
}
.settings-menu .v-list-item:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Efecto al pasar el mouse */
}
.btn-settings{
  
}
  </style>