<template>
    <div id="container">
        <v-card id="login">
            <img :src="imageSrc" alt="GROW">
            <h1 class="mb-2 mt-2 text-center">Recuperar contraseña</h1>
            <v-form ref="loginForm" @submit.prevent>
                <p class="text-white text-center mb-2">Introduce el correo electrónico asociado a tu cuenta y, si está registrado, te enviaremos un enlace para restablecer tu contraseña.</p>
              <v-text-field
                label="Email"
                v-model="username"
                variant="solo"
                type="email"
                :rules="[rules.email]"
                class="mb-2"
                >
              </v-text-field>
              <v-btn block color="grey-lighten-2" @click="$router.push({ name: 'Login' });" class="mb-2">Cancelar</v-btn>
              <v-btn block color="accent" @click="login" :loading="loading">Enviar correo</v-btn>

            </v-form>            
        </v-card>
    </div>
  </template>
  <script>

  import axios from 'axios';
  import { mapActions } from 'vuex';


export default {
  data() {
    return {
      username: '',
      imageSrc: require('../assets/GROW.png'),
      rules: {
        email: value => {
          const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          return emailPattern.test(value) ? true : 'Debes ingresar un correo electrónico válido';
        }
      },
      loading: false
    };
  },
  methods: {
    ...mapActions(['triggerSnackbar']),
    async login() {
      this.loading = true;
      try {
        const valid = await this.$refs.loginForm.validate();
        if(!valid.valid){
          this.loading = false;
          return
        }
        const res = await axios.post(`${process.env.VUE_APP_URL}/login/changePassword`,{
            email: this.username
        });
        const statusCode = res?.status;
        const message = res?.data?.message;
        if(statusCode != 200 && statusCode != '200'){
          this.showSnackbar(message, "error", 3000);
          this.loading = false;
          return;
        }
        
        if(!message){
          this.showSnackbar("Hubo un error intenta de nuevo", "error", 3000);
          this.loading = false;
          return;
        }

        this.showSnackbar("Se ha enviado el correo", "success", 3000);
        this.loading = false;
        this.$router.push({ name: 'Login' });
      } catch (error) {
        console.error(error);
        this.showSnackbar("Hubo un error intenta de nuevo", "error", 3000);
        this.loading = false;
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword; // Alternar entre mostrar y ocultar la contraseña
    },
    showSnackbar(message, color, timeout) {
      this.triggerSnackbar({
        message: message,
        color: color,
        timeout: timeout
      });
    }
  }
};
</script>

<style scope>

#login {
    margin: 3rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    min-width: 350px;
    max-width: 500px;
    padding: 40px;
    background-color: rgba(255, 255, 255, 0.5) !important;

}

.custom-link {
  color: var(--v-accent-base); /* Usar el color accent de Vuetify */
  text-decoration: none; /* Eliminar subrayado si no lo deseas */
}

.custom-link:visited {
  color: var(--v-accent-base); /* Mantener el color accent cuando el enlace ha sido visitado */
}

.custom-link:hover {
  color: var(--v-accent-darken1); /* Cambiar el color al hacer hover */
  text-decoration: underline;
}
</style>