<template >
    <Loader v-if="loading" />
    <ChangePassword :title="title" v-else-if="!error && !loading"/>
    <TokenExpired v-else/>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

import ChangePassword from '@/components/ChangePassword.vue';
import TokenExpired from '@/components/TokenExpired.vue';
import Loader from '@/components/Loader.vue'


import axios from 'axios';

export default defineComponent({
name: 'PasswordView',

components: {
    ChangePassword,
    TokenExpired,
    Loader
    },
    data(){
        return {
            title: this.$route.name == "Create password" ? "Crear contraseña" : "Cambiar contraseña",
            error: false,
            loading: true
        }
    },
    methods: {
        ...mapActions(['updateEmail']), // Mapear la acción de Vuex
    },
    async mounted(){
        const title = this.title;
        console.log(title)
        if(title == 'Crear contraseña'){
            this.loading = false;
            this.error = false;
            return;
        }
        const token = this.$route?.query?.token;
        console.log(token)
        try{
            const verifyToken = await axios.post(`${process.env.VUE_APP_URL}/login/verifyChangeToken`, {
              email: 'test',
              token: token
            });

            if(verifyToken?.data?.message != 'Token Valido'){
                this.loading = false;
                this.error = true;
            }

            const email = verifyToken?.data?.relatedEmail;

            this.updateEmail(email);

            this.loading = false

            console.log(verifyToken)
        }catch(err){
            console.log(err)
        }
        
    }
});
</script>

<style>

</style>